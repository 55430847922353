import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import * as yup from "yup";
import uniq from 'lodash/uniq';
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";

const schema = yup.string().email();

function isValidEmail(value) {
  try {
    schema.validateSync(value);

    return true;
  } catch (e) {
    // not valid email
    return false;
  }
}

const customComponents = {
  DropdownIndicator: null,
  MultiValueLabel: (props) => {
    if (!isValidEmail(props.data.value)) {
      return (
        <div className="flex items-center text-red-R500 bg-red-R500/10">
          <div className="px-1">
            <FontAwesomeIcon icon={faExclamationCircle}></FontAwesomeIcon>
          </div>
          <components.MultiValueLabel {...props} />
        </div>
      );
    }

    return <components.MultiValueLabel {...props} />;
  },
};

const createOption = (label) => ({
  label,
  value: label,
});

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 100,
    alignItems: 'flex-start',
    boxShadow: 'none',
  }),
  multiValue: (styles, { data }) => {
    if (!isValidEmail(data.value)) {
      return {
        ...styles,
        backgroundColor: 'white',
        border: '1px dashed #f92c44',
      };
    }

    return styles;
  },
};

export default function EmailMultiSelect({ id, onChange, onBlur, disabled, value, placeholder }) {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
  };

  const handleKeyDown = (event) => {
    if (!inputValue) {
      return;
    }

    switch (event.key) {
      case ' ': // spacebar
      case ',':
      case 'Enter':
      case 'Tab':
        setInputValue('');
        onChange(uniq([...value, inputValue]));

        event.preventDefault();
    }
  };

  function handleBlur(event) {
    if (inputValue) {
      onChange(uniq([...value, inputValue]));
      setInputValue('');
    }

    if (null !== onBlur && typeof onBlur === 'function') {
      onBlur(event);
    }
  }

  return (
    <CreatableSelect
      id={id}
      components={customComponents}
      inputValue={inputValue}
      isClearable={false}
      isMulti
      menuIsOpen={false}
      onChange={values => onChange(values ? values.map(v => v.value) : [])}
      onBlur={handleBlur}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      isDisabled={disabled}
      value={value ? value.map(v => createOption(v)) : []}
      styles={customStyles}
      className="email-multiselect"
      classNamePrefix="email-multiselect"
    />
  );
}
